import style from './Link.scss';

import React from 'react';
import NextLink, { LinkProps } from 'next/link';
import cloneDeep from 'lodash/cloneDeep';
import { build } from '@vsemayki/url-resolver';
import { ContextState } from '@store/ducks/application/context';
import clsx from 'clsx';

type TLinkProps = {
    context: ContextState;
    native?: boolean;
    nextLinkProps?: Partial<LinkProps>;
    nativeLinkProps?: JSX.IntrinsicElements['a'];
    disabled?: boolean;
};

export const Link: React.FC<TLinkProps> = React.forwardRef<
    HTMLAnchorElement,
    TLinkProps
>(
    (
        {
            context,
            children,
            native = false,
            nextLinkProps,
            nativeLinkProps,
            disabled,
        },
        ref
    ) => {
        if (context.name === 'unknown') {
            return (
                <a
                    tabIndex={disabled ? -1 : undefined}
                    {...nativeLinkProps}
                    className={clsx(
                        style.link,
                        disabled && style.disabled,
                        nativeLinkProps?.className
                    )}
                >
                    {children}
                </a>
            );
        }

        const prev = cloneDeep(context);
        let url = '/';
        try {
            url = build(prev);
        } catch (e) {
            console.error(new Error(e));
        }
        if (native) {
            return (
                <a
                    tabIndex={disabled ? -1 : undefined}
                    {...nativeLinkProps}
                    className={clsx(
                        style.link,
                        disabled && style.disabled,
                        nativeLinkProps?.className
                    )}
                    href={url}
                >
                    {children}
                </a>
            );
        }
        return (
            <NextLink {...nextLinkProps} href={`/${prev.name}`} as={url}>
                <a
                    tabIndex={disabled ? -1 : undefined}
                    {...nativeLinkProps}
                    ref={ref}
                    className={clsx(
                        style.link,
                        disabled && style.disabled,
                        nativeLinkProps?.className
                    )}
                >
                    {children}
                </a>
            </NextLink>
        );
    }
);
Link.displayName = 'LinkRef';

export default Link;
